<template>
    <div class="categories-container" v-if="Object.keys(categList).length > 0">
        <div class="legend">
            <el-row :gutter="10" class="legend-items">
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-for="(item, i) in legend" :key="i">
                    <div>
                        <template v-if="item.icon.startsWith('el-icon')">
                            <i :class="item.icon" :style="{ color: item.color }" />
                        </template>
                        <template v-else>
                            <svg-icon :class="item.color" :icon-class="item.icon" />
                        </template>
                        <span v-html="item.text"></span>
                    </div>
                </el-col>

                <!-- <el-col :xs="20" :sm="12" :md="9" :lg="8" :xl="6">
					<span>
						<svg-icon class="warning" icon-class="hourglass" />
						{{$t('account.categoryaccess.pendingrequest')}}
					</span>
				</el-col>

				<el-col :xs="20" :sm="12" :md="9" :lg="8" :xl="6">
					<span>
						<svg-icon class="warning" icon-class="file" />
						{{$t('account.categoryaccess.waitingdoc')}}
					</span>
				</el-col>

				<el-col :xs="20" :sm="12" :md="9" :lg="8" :xl="6">
					<span>
						<svg-icon class="green" icon-class="checkmark" />
						{{$t('account.categoryaccess.hasaccess')}}</span>
				</el-col>

				<el-col :xs="20" :sm="12" :md="9" :lg="8" :xl="6">
					<span>
						<svg-icon class="danger" icon-class="minus" />
						{{$t('account.categoryaccess.accessrequestrejected')}}</span>
				</el-col>

				<el-col :xs="20" :sm="12" :md="9" :lg="8" :xl="6">
					<span>
						<svg-icon class="blue" icon-class="unlock" /> {{$t('account.categoryaccess.canrequestaccess')}}
						({{$t('account.categoryaccess.clickon')}}
						<svg-icon class="blue" icon-class="unlock" />)
					</span>
				</el-col> -->
            </el-row>
        </div>
        <el-row :gutter="10">
            <el-col v-for="(categs, index) in categList" :key="index" :xs="20" :sm="12" :md="9" :lg="8" :xl="6">
                <c-list :catLevel.sync="index" :catList.sync="categs" :selected.sync="selected"></c-list>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { getCategoriesForAccess, requestCategoryAccess } from '@/api/cont';
import { EventBus } from '@/utils/Bus.js';

const list = () => import('./list.vue');

import i18n from '@/lang';
export default {
    components: {
        'c-list': list,
    },
    data() {
        return {
            cCategory: 0,
            categList: [],
            selected: [],
            legend: [
                {
                    color: 'red',
                    icon: 'cancel',
                    text: i18n.t('account.categoryaccess.notpermitted'),
                },
                {
                    color: 'warning',
                    icon: 'hourglass',
                    text: i18n.t('account.categoryaccess.pendingrequest'),
                },
                {
                    color: 'warning',
                    icon: 'file',
                    text: i18n.t('account.categoryaccess.waitingdoc'),
                },
                {
                    color: 'green',
                    icon: 'checkmark',
                    text: i18n.t('account.categoryaccess.hasaccess'),
                },
                {
                    color: 'danger',
                    icon: 'minus',
                    text: i18n.t('account.categoryaccess.accessrequestrejected'),
                },
                {
                    color: 'blue',
                    icon: 'unlock',
                    text: i18n.t('account.categoryaccess.canrequestaccess'),
                },
                {
                    color: '#ff601d',
                    icon: 'el-icon-s-flag',
                    text: 'Comision categorie',
                },
                {
                    color: '#67c23a',
                    icon: 'el-icon-s-flag',
                    text: 'Comision aplicat',
                },
            ],
        };
    },
    methods: {
        getCategories(level) {
            getCategoriesForAccess({
                currentCategory: this.cCategory,
            }).then((res) => {
                this.$set(this.categList, level, res.message);
            });
        },
        requestCategoryAccess(categ) {

            this.$confirm(`Sigur doriti acces la categoria ${categ.categories_name}?`, 'Atentie', {
                confirmButtonText: 'Da',
                cancelButtonText: 'Nu',
                type: 'warning',
            })
                .then(() => {
                    requestCategoryAccess({
                        categ_id: categ.categories_id,
                    })
                        .then((res) => {
                            if (
                                res != null &&
                                typeof res.message !== 'undefined' &&
                                typeof res.message.success !== 'undefined' &&
                                parseInt(res.message.success) === 1
                            ) {
                                if (res.message.type != null && res.message.type === 1) {
                                    this.sbMsg({
                                        type: 'success',
                                        message: `Cererea de acces a fost aprobata automat! Puteti incepe listarea produselor imediat dupa semnarea si incarcarea documentului aditional.`,
                                    });

                                  this?.categList[1]?.forEach((rowCategData, key) => {
                                    if (rowCategData?.categories_id === categ?.categories_id) {
                                      this.categList[1][key].hasAccess = 2;
                                    }
                                  });
                                } else {
                                    this.sbMsg({
                                        type: 'success',
                                        message: `Cererea de acces a fost inregistrata. Imediat ce aceasta va fi aprobata veti putea lista produse in categoria respectiva.`,
                                    });
                                    this?.categList[1]?.forEach((rowCategData, key) => {
                                        if (rowCategData?.categories_id === categ?.categories_id) {
                                            this.categList[1][key].accessRequest = 1;
                                        }
                                    });
                                }
                                // this.resetFields();
                            }
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'requestCategoryAccess',
                                    params: {
                                        categ_id: categ.categories_id,
                                    },
                                },
                            });
                        });
                })
                .catch(() => {});
        },
        selectCategory(data) {
            this.cCategory = data.id;
            this.categList.length = data.nextLevel; // Removing any rendered categories from level 3 or more
            this.$set(this.selected, data.nextLevel - 1, data.id);
            this.selected.length = data.nextLevel;
            this.getCategories(data.nextLevel);
        },
        resetFields() {
            this.cCategory = 0;
            this.categList = [];
            this.selected = [];
            this.getCategories(0);
        },
    },
    computed: {
        currentCategory() {
            return this.categId;
        },
        reactiveCategList() {
            return this.categList;
        },
    },
    watch: {},
    created() {
        this.getCategories(0);
    },
    mounted() {
        EventBus.$on('category-selected', (data) => {
            if (typeof data !== 'undefined') this.selectCategory(data);
        });

        EventBus.$on('request-category-access', (categ) => {
            if (typeof categ !== 'undefined') this.requestCategoryAccess(categ);
        });
    },
    destroyed() {
        EventBus.$off();
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.legend-items {
    font-size: 14px;
    font-weight: bold;
    color: #303133;
    text-align: left;

    .el-col {
        margin: 8px 0;
    }

    .el-col > div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: start;
        align-items: center;
    }

    .svg-icon {
        font-size: 16px;
    }

    span {
        margin-right: 10px;
    }

    .svg-icon.red {
        color: #909399;
    }

    .svg-icon.green {
        color: #67c23a;
    }

    .svg-icon.blue {
        color: #409eff;
    }

    .svg-icon.warning {
        color: #e6a23c;
    }

    .svg-icon.danger {
        color: #f56c6c;
    }
}
</style>
